<template>
  <div class="switching">
    <Header />
    <b-container class="switching-container">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <div class="row justify-content-center mx-auto my-auto">
            <div class="text-center">
              <img src="@/assets/img/loading/loading.gif" width="100px" />
            </div>
          </div>
        </template>
        <b-row class="justify-content-md-center">
          <div class="col-lg-4 col-md-6">
            <div>
              <b-card
                class="product-information mb-4 d-none d-lg-block d-md-block"
              >
                <div class="fund-name">
                  <b-row>
                    <b-col cols="2">
                      <div v-if="product.image == null">
                        <img
                          :src="
                            'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                            product.fund_name
                          "
                        />
                      </div>
                      <div v-else>
                        <img
                          :src="`${imageCore}/images/products/${product.image}`"
                          alt="Fund Image"
                        />
                      </div>
                    </b-col>
                    <b-col>
                      <h4>
                        {{ product.fund_name }}
                      </h4>
                      <p v-text="product.investment_manager.im_name"></p>
                    </b-col>
                  </b-row>
                </div>
                <div class="fund-type">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Jenis Reksa Dana</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">{{ product.fund_type }}</p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="release-date">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Tanggal Peluncuran</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ moment(product.launch_date).format(" DD MMM YYYY") }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="min-buy">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Min. Pembelian</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product.fund_ccy }}{{ formatPrice(product.min_buy) }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <!-- <div class="min-buy-next">
                <b-row class="mt-2">
                  <b-col class="my-auto">
                    <p class="variant">Pembelian Selanjutnya</p>
                  </b-col>
                  <b-col class="my-auto">
                    <p class="value">
                      {{ product.fund_ccy
                      }}{{ formatPrice(product.min_buy_next) }}
                    </p>
                  </b-col>
                </b-row>
              </div>
              <hr /> -->
                <div class="min-sell">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Min. Penjualan Kembali</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product.fund_ccy
                        }}{{ formatPrice(product.min_sell) }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="custodian-bank">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Bank Kustodian</p>
                    </b-col>
                    <b-col class="my-auto">
                      <div>
                        <p class="custodian-bank-name">
                          {{ product.custodian_bank.cb_name }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <template v-if="user.individual != null">
                  <template
                    v-if="
                      user.individual.investors_risk_profile.toLowerCase() ==
                        'low' && product.fund_type.toLowerCase() == 'pasar uang'
                    "
                  >
                    <b-button
                      block
                      :href="'/switching/' + fund_code_out + '/' + fund_code_in"
                      class="mt-2 btn-tertiary py-2"
                      >Alihkan Sekarang</b-button
                    >
                  </template>
                  <template
                    v-else-if="
                      user.individual.investors_risk_profile.toLowerCase() ==
                        'moderate' &&
                      (product.fund_type.toLowerCase() == 'terproteksi' ||
                        product.fund_type.toLowerCase() ==
                          'penyertaan terbatas' ||
                        product.fund_type.toLowerCase() == 'campuran' ||
                        product.fund_type.toLowerCase() == 'pendapatan tetap' ||
                        product.fund_type.toLowerCase() == 'pasar uang')
                    "
                  >
                    <b-button
                      block
                      :href="'/switching/' + fund_code_out + '/' + fund_code_in"
                      class="mt-2 btn-tertiary"
                      >Alihkan Sekarang</b-button
                    >
                  </template>
                  <template
                    v-else-if="
                      user.individual.investors_risk_profile.toLowerCase() ==
                      'high'
                    "
                  >
                    <b-button
                      block
                      :href="'/switching/' + fund_code_out + '/' + fund_code_in"
                      class="mt-2 btn-tertiary"
                      >Alihkan Sekarang</b-button
                    >
                  </template>
                  <template v-else>
                    <b-button
                      block
                      v-b-modal.modal-risk-profile
                      class="mt-2 btn-tertiary"
                      >Alihkan Sekarang</b-button
                    >
                  </template>
                </template>

                <!-- <b-button
                block
                :href="'/switching/' + fund_code_out + '/' + fund_code_in"
                class="mt-2 btn-tertiary"
                >Alihkan Sekarang</b-button
              > -->

                <!-- <div class="fee">
                <b-row class="mt-2">
                  <b-col >
                    <p class="variant">Biaya Manajemen</p>
                  </b-col>
                  <b-col >
                    <p class="value"></p>
                  </b-col>
                </b-row>
              </div> -->

                <!-- <div class="subscription-cost">
                <b-row class="mt-2">
                  <b-col >
                    <p class="variant">Biaya Pengalihan</p>
                  </b-col>
                  <b-col >
                    <p class="value">
                      {{ product ? product.fee_switch_text : "GRATIS" }}
                    </p>
                  </b-col>
                </b-row>
              </div> -->

                <!-- <b-button
                block
                to="/panduan-pembelian-reksa-dana"                
                class="mt-2 btn-tertiary"
                >Beli</b-button
              > -->

                <!-- 
              <b-button
                block
                :disabled="!checked"
                href="#"
                class="mt-2 btn-tertiary"
                >Beli</b-button
              > -->

                <!-- Button with modal -->
                <!-- <b-button
                block
                v-b-modal.modal-risk-profile
                class="mt-2 btn-tertiary"
                >Beli</b-button
              > -->
              </b-card>

              <b-card
                class="product-information mb-4 d-block d-lg-none d-md-none"
              >
                <div class="fund-name">
                  <b-row>
                    <b-col cols="2">
                      <img
                        :src="
                          'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                          product.fund_name
                        "
                      />
                    </b-col>
                    <b-col>
                      <h4>
                        {{ product.fund_name }}
                      </h4>
                      <p v-text="product.investment_manager.im_name"></p>
                    </b-col>
                  </b-row>
                </div>
              </b-card>

              <b-card
                class="transaction-cost mb-4 d-none d-lg-block d-md-block"
              >
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="document">
                      <p class="">
                        <img
                          src="@/assets/img/icons/product/transaction-cost-icon.svg"
                          alt="Biaya Transaksi"
                          class="mr-2"
                        />Biaya Transaksi
                      </p>
                    </div>
                  </div>
                </b-row>
                <div class="subscription-cost">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Biaya Pembelian</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product ? product.fee_buy_text : "GRATIS" }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="redeem-cost">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Biaya Penjualan</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product ? product.fee_sell_text : "GRATIS" }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <!-- <div class="switching-cost">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Biaya Pengalihan</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product ? product.fee_switch_text : "GRATIS" }}
                      </p>
                    </b-col>
                  </b-row>
                </div> -->
              </b-card>

              <b-card class="transaction-cost mb-4 d-block d-lg-none d-md-none">
                <b-row class="fund-performance text-left">
                  <div class="col-6">
                    <div class="nab mb-2">
                      <p>NAV/Unit</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="nab-value">
                      <p>
                        <span>{{ product.fund_ccy }}</span
                        >{{ formatNab(nav_history.nav_per_unit) }}
                      </p>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="aum mb-2">
                      <p>Dana Kelolaan</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="aum-value">
                      <p>
                        <span>{{ product.fund_ccy }}</span
                        >{{ formatPrice(nav_history.navAum) }}
                      </p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="participation-unit mb-2">
                      <p>Unit Penyertaan</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="participation-unit-value">
                      <p>
                        {{ formatNab(nav_history.navUnit)
                        }}<span class="ml-1">unit</span>
                      </p>
                    </div>
                  </div>
                </b-row>
              </b-card>

              <b-card
                class="downloadable-document mb-4 d-none d-lg-block d-md-block"
              >
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="document">
                      <p class="">
                        <img
                          src="@/assets/img/icons/document/document-icon.svg"
                          alt="Download Dokumen"
                          class="mr-2"
                        />Download Dokumen
                      </p>
                    </div>
                  </div>
                </b-row>
                <div class="prospectus">
                  <b-row class="mt-2">
                    <b-col>
                      <p class="variant">Prospektus</p>
                    </b-col>
                    <b-col>
                      <b-link
                        style="text-decoration: none"
                        :href="
                          imageCore +
                          '/prospectus/download/' +
                          product.prospectus
                        "
                        ><p class="value">
                          <img
                            src="@/assets/img/icons/download/download-icon.svg"
                            alt="Disclaimer"
                            class="mx-2"
                          />Download
                        </p></b-link
                      >
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="fund-fact-sheet">
                  <b-row class="mt-2">
                    <b-col>
                      <p class="variant">Fund Fact Sheet</p>
                    </b-col>
                    <b-col>
                      <b-link
                        :href="
                          `${imageCore}/fund_fact_sheet/download/` +
                          product.fund_fact_sheet
                        "
                        ><p class="value">
                          <img
                            src="@/assets/img/icons/download/download-icon.svg"
                            alt="Download"
                            class="mx-2"
                          />Download
                        </p></b-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </b-card>

              <b-card class="terms d-none d-lg-block d-md-block">
                <div class="price-terms">
                  <b-row>
                    <div class="col-lg-12 col-md-6">
                      <p>
                        <img
                          src="@/assets/img/icons/product/transaction-rules.svg"
                          alt="Ketentuan Transaksi"
                          class="mx-2"
                        />Ketentuan Transaksi
                      </p>
                    </div>
                  </b-row>
                </div>
                <div class="transaction-time-before ml-3">
                  <b-row class="mt-2">
                    <p class="before">Transaksi sebelum 13:00 WIB</p>
                    <p class="before-detail">
                      Batas waktu konfirmasi Pengalihan Reksa Dana paling lambat
                      diterima dan tercatat oleh Aplikasi CGS iTrade Fund adalah pukul
                      13:00 WIB untuk diproses menggunakan harga NAV/Unit di
                      hari bursa yang sama.
                    </p>
                  </b-row>
                </div>
                <hr />
                <div class="transaction-time-after ml-3">
                  <b-row class="mt-2">
                    <p class="after">Transaksi setelah 13:00 WIB</p>
                    <p class="after-detail">
                      Transaksi Pengalihan yang diterima dan tercatat melebihi
                      batas waktu pukul 13:00 WIB akan diproses dengan
                      menggunakan harga NAV/Unit hari bursa berikutnya.
                    </p>
                  </b-row>
                </div>
                <hr />
                <div class="transaction-time-before ml-3">
                  <b-row class="mt-2">
                    <p class="before">Biaya dan Perpajakan</p>
                  </b-row>
                  <b-row>
                    <p class="before-detail">
                      Biaya dan Perpajakan akan timbul atas dasar ketentuan
                      peraturan perundangan-undangan yang berlaku, sesuai dengan
                      transaksi yang dimiliki.
                    </p>
                  </b-row>
                </div>
              </b-card>
            </div>
          </div>

          <div class="col-lg-8 col-md-6">
            <div>
              <b-card
                class="product-performance mb-4 d-none d-lg-block d-md-block"
              >
                <b-row class="fund-performance">
                  <b-col class="mt-3">
                    <div class="nab mb-2">
                      <span>NAV/Unit</span>
                    </div>
                    <div class="nab-value">
                      <p>
                        <span class="mr-1">{{ product.fund_ccy }}</span
                        >{{ formatNab(nav_history.nav_per_unit) }}
                      </p>
                    </div>
                  </b-col>
                  <b-col class="mt-3">
                    <div class="aum mb-2">
                      <span>Dana Kelolaan</span>
                    </div>
                    <div class="aum-value">
                      <p>
                        <span class="mr-1">{{ product.fund_ccy }}</span
                        >{{ formatPrice(nav_history.navAum) }}
                      </p>
                    </div>
                  </b-col>
                  <b-col class="mt-3">
                    <div class="participation-unit mb-2">
                      <span>Unit Penyertaan</span>
                    </div>
                    <div class="participation-unit-value">
                      <p>
                        {{ formatNab(nav_history.navUnit)
                        }}<span class="ml-1">unit</span>
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </b-card>

              <b-card class="nav-chart mb-4 p-4">
                <b-row>
                  <b-col>
                    <h4>Performa NAV</h4>
                  </b-col>
                </b-row>
                <b-row>
                  <p>Periode: {{ product.nav.nav_date }}</p>
                </b-row>
                <b-row class="justify-content-center mb-4">
                  <NavPerformanceChart :idProduct="parseInt(id_product)" />
                </b-row>
              </b-card>
              <b-card class="aum-chart p-4 mb-4">
                <b-row>
                  <b-col>
                    <h4>
                      Pertumbuhan Dana Kelolaan<span
                        >(dalam satuan Miliar)</span
                      >
                    </h4>
                  </b-col>
                </b-row>
                <b-row>
                  <p>Periode: {{ product.nav.nav_date }}</p>
                </b-row>
                <b-row class="justify-content-center mb-4">
                  <AumPerformanceChart :idProduct="parseInt(id_product)" />
                </b-row>
              </b-card>

              <b-card
                class="product-information mb-4 d-block d-lg-none d-md-none"
              >
                <div class="fund-type">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Jenis Reksa Dana</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">{{ product.fund_type }}</p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="release-date">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Tanggal Peluncuran</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ moment(product.launch_date).format(" DD MMM YYYY") }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="min-buy">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Min. Pembelian</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product.fund_ccy }}{{ formatPrice(product.min_buy) }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="min-sell">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Min. Penjualan Kembali</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product.fund_ccy
                        }}{{ formatPrice(product.min_sell) }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="custodian-bank">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Bank Kustodian</p>
                    </b-col>
                    <b-col class="my-auto">
                      <div>
                        <p class="custodian-bank-name">
                          {{ product.custodian_bank.cb_name }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
              <b-card class="transaction-cost mb-4 block d-lg-none d-md-none">
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="document">
                      <p class="">
                        <img
                          src="@/assets/img/icons/product/transaction-cost-icon.svg"
                          alt="Biaya Transaksi"
                          class="mr-2"
                        />Biaya Transaksi
                      </p>
                    </div>
                  </div>
                </b-row>
                <div class="subscription-cost">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Biaya Pembelian</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product ? product.fee_buy_text : "GRATIS" }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="redeem-cost">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Biaya Penjualan</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product ? product.fee_sell_text : "GRATIS" }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <!-- <div class="switching-cost">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Biaya Pengalihan</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product ? product.fee_switch_text : "GRATIS" }}
                      </p>
                    </b-col>
                  </b-row>
                </div> -->
              </b-card>

              <b-card
                class="downloadable-document mb-4 d-block d-lg-none d-md-none"
              >
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="document">
                      <p>
                        <img
                          src="@/assets/img/icons/document/document-icon.svg"
                          alt="Download Dokumen"
                          class="mr-2"
                        />Download Dokumen
                      </p>
                    </div>
                  </div>
                </b-row>
                <div class="prospectus">
                  <b-row class="mt-2">
                    <b-col>
                      <p class="variant">Prospektus</p>
                    </b-col>
                    <b-col>
                      <b-link
                        style="text-decoration: none"
                        :href="
                          imageCore +
                          '/prospectus/download/' +
                          product.prospectus
                        "
                        ><p class="value">
                          <img
                            src="@/assets/img/icons/download/download-icon.svg"
                            alt="Disclaimer"
                            class="mx-2"
                          />Download
                        </p></b-link
                      >
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="fund-fact-sheet">
                  <b-row class="mt-2">
                    <b-col>
                      <p class="variant">Fund Fact Sheet</p>
                    </b-col>
                    <b-col>
                      <b-link
                        :href="
                          `${imageCore}/fund_fact_sheet/download/` +
                          product.fund_fact_sheet
                        "
                        ><p class="value">
                          <img
                            src="@/assets/img/icons/download/download-icon.svg"
                            alt="Download"
                            class="mx-2"
                          />Download
                        </p></b-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </b-card>

              <b-card class="terms mb-4 p-4 d-block d-lg-none d-md-none">
                <div class="price-terms">
                  <b-row>
                    <div class="col-lg-12 col-md-6">
                      <p>
                        <img
                          src="@/assets/img/icons/product/transaction-rules.svg"
                          alt="Ketentuan Transaksi"
                          class="mr-2"
                        />Ketentuan Transaksi
                      </p>
                    </div>
                  </b-row>
                </div>
                <div class="transaction-time-before">
                  <b-row class="mt-2">
                    <div class="col-lg-12 col-md-6">
                      <p class="before">
                        Transaksi sebelum
                        {{ product.batas_waktu_transaksi }} WIB
                      </p>
                    </div>
                    <div class="col-lg-12 col-md-6">
                      <p class="before-detail">
                        Batas waktu konfirmasi Pembelian Reksa Dana paling
                        lambat diterima dan tercatat oleh Aplikasi CGS iTrade Fund
                        adalah pukul 13:00 WIB untuk diproses menggunakan harga
                        NAV/Unit di hari bursa yang sama.
                      </p>
                    </div>
                  </b-row>
                </div>
                <hr />
                <div class="transaction-time-after">
                  <b-row class="mt-2">
                    <div class="col-lg-12 col-md-6">
                      <p class="after">
                        Transaksi setelah
                        {{ product.batas_waktu_transaksi }} WIB
                      </p>
                    </div>
                    <div class="col-lg-12 col-md-6">
                      <p class="after-detail">
                        Transaksi Pembelian yang diterima dan tercatat melebihi
                        batas waktu pukul 13:00 WIB akan diproses dengan
                        menggunakan harga NAV/Unit hari bursa berikutnya.
                      </p>
                    </div>
                  </b-row>
                </div>
                <hr />
                <div class="transaction-time-before ml-3">
                  <b-row class="mt-2">
                    <p class="before">Biaya dan Perpajakan</p>
                  </b-row>
                  <b-row>
                    <p class="before-detail">
                      Biaya dan Perpajakan akan timbul atas dasar ketentuan
                      peraturan perundangan-undangan yang berlaku, sesuai dengan
                      transaksi yang dimiliki.
                    </p>
                  </b-row>
                </div>
              </b-card>

              <div class="mb-4 d-block d-lg-none d-md-none">
                <template v-if="user.individual != null">
                  <template
                    v-if="
                      user.individual.investors_risk_profile.toLowerCase() ==
                        'low' && product.fund_type.toLowerCase() == 'pasar uang'
                    "
                  >
                    <b-button
                      block
                      :href="'/switching/' + fund_code_out + '/' + fund_code_in"
                      class="mt-2 btn-tertiary py-2"
                      >Alihkan Sekarang</b-button
                    >
                  </template>
                  <template
                    v-else-if="
                      user.individual.investors_risk_profile.toLowerCase() ==
                        'moderate' &&
                      (product.fund_type.toLowerCase() == 'terproteksi' ||
                        product.fund_type.toLowerCase() ==
                          'penyertaan terbatas' ||
                        product.fund_type.toLowerCase() == 'campuran' ||
                        product.fund_type.toLowerCase() == 'pendapatan tetap' ||
                        product.fund_type.toLowerCase() == 'pasar uang')
                    "
                  >
                    <b-button
                      block
                      :href="'/switching/' + fund_code_out + '/' + fund_code_in"
                      class="mt-2 btn-tertiary"
                      >Alihkan Sekarang</b-button
                    >
                  </template>
                  <template
                    v-else-if="
                      user.individual.investors_risk_profile.toLowerCase() ==
                      'high'
                    "
                  >
                    <b-button
                      block
                      :href="'/switching/' + fund_code_out + '/' + fund_code_in"
                      class="mt-2 btn-tertiary"
                      >Alihkan Sekarang</b-button
                    >
                  </template>
                  <template v-else>
                    <b-button
                      block
                      v-b-modal.modal-risk-profile
                      class="mt-2 btn-tertiary"
                      >Alihkan Sekarang</b-button
                    >
                  </template>
                </template>
              </div>

              <div class="disclaimer">
                <b-row class="justify-content-center mb-4">
                  <div class="col-lg-12 col-md-6">
                    <div class="card p-1">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-12 col-md-6 d-flex">
                            <div class="align-items-center">
                              <img
                                src="@/assets/img/icons/information/information-ic.svg"
                                alt="Disclaimer"
                                class="mr-2"
                              />
                            </div>
                            <p>
                              Kinerja Reksa dana yang ditampilkan pada halaman
                              ini merupakan kinerja historis masa lalu, dan
                              tidak mencerminkan kepastian kinerja di masa yang
                              akan datang.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-row>
              </div>
            </div>
          </div>
        </b-row>
        <div class="risk-profile-modal">
          <b-modal
            id="modal-risk-profile"
            size="lg"
            class="modal-risk-profile"
            centered
            hide-footer
          >
            <div class="d-block text-center modal-text-header p-4">
              <div class="row justify-content-start text-left">
                <div class="col-lg-12 col-md-6">
                  <p class="disclaimer-text">
                    Profil Risiko Anda lebih rendah dibandingkan karakteristik
                    risiko Reksa Dana ini.
                  </p>
                </div>
              </div>
            </div>
            <div class="row px-4">
              <div class="col-lg-12 col-md-6 d-flex">
                <div class="align-items-center">
                  <div class="custom-control custom-checkbox">
                    <input
                      id="agree"
                      type="checkbox"
                      value="accepted"
                      class="custom-control-input mr-2"
                      v-model="checked"
                    />
                    <label
                      for="agree"
                      class="custom-control-label text-justify"
                    >
                      Saya telah membaca, memahami, dan menyetujui seluruh isi
                      <b-link
                        :href="
                          imageCore +
                          '/prospectus/download/' +
                          product.prospectus
                        "
                        class="prospectus-link text-danger text-decoration-none"
                        target="_blank"
                        >Prospektus</b-link
                      >
                      dan memahami risiko investasi yang saya buat.
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="this.user.individual != null">
              <b-row class="justify-content-center pb-4 px-3">
                <div class="col-lg-6 col-md-6">
                  <b-button
                    :href="'/switching/' + fund_code_out + '/' + fund_code_in"
                    block
                    class="mt-3 mx-2 btn-tertiary py-2"
                    :disabled="!checked"
                    >Alihkan Sekarang</b-button
                  >
                </div>
                <div class="col-lg-6 col-md-6">
                  <b-button
                    block
                    class="mt-3 mx-2 btn-outline-primary"
                    @click="$bvModal.hide('modal-risk-profile')"
                    >Nanti Saja</b-button
                  >
                </div>
              </b-row>
            </template>
          </b-modal>
        </div>
      </b-skeleton-wrapper>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";
import NavPerformanceChart from "@/components/partials/product/navChart/NavPerformanceChart.vue";
import AumPerformanceChart from "@/components/partials/product/aumChart/AumPerformanceChart.vue";

export default {
  name: "SwitchingProductDetail",
  metaInfo: {
    title: "Detail Reksadana Pengalihan | CGS iTrade Fund",
  },
  components: {
    Header,
    NavPerformanceChart,
    AumPerformanceChart,
    Footer,
  },
  data() {
    return {
      loading: false,
      loadingTime: 0,
      maxLoadingTime: 3,
      product: {
        investment_manager: {
          im_name: "",
        },
        custodian_bank: {
          cb_name: "",
        },
        fund_type: "",
        nav: {
          nav_date: "",
          nav_per_unit: "",
        },
      },
      fund_code_out: "",
      fund_code_in: "",
      nav_history: {},
      checked: false,
      id_product: null,
      imageCore: process.env.VUE_APP_IMAGE_URL,
    };
  },
  computed: {
    ...mapGetters({
      ifua_number: "auth/ifua_number",
      bearer_token: "bearer_token",
      app_url: "app_url",
      user: "auth/user",
    }),
  },
  created() {
    this.fund_code_in = this.$route.params.fund_code_in;
    this.fund_code_out = this.$route.params.fund_code_out;
    this.id_product = this.$route.params.id;
    this.fetchFund();
    this.fetchNavHistory();
    this.$_loadingTimeInterval = null;
  },
  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval();

        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.loadingTime++;
          }, 1000);
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false;
        }
      }
    },
  },
  methods: {
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    async fetchFund() {
      this.loading = true;

      let response = await axios({
        method: "GET",
        url: `${this.app_url}product/${this.fund_code_in}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${this.bearer_token}`,
        },
      });
      if (!response.data.data) {
        return this.$router.push("/NOT-FOUND");
      }
      this.product = response.data.data;
      this.loadingTime = 0;
    },

    async fetchNavHistory() {
      this.loading = true;

      let response = await axios({
        method: "GET",
        url: `3041?api=1&type=1&fund_code=${this.fund_code_out}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${this.bearer_token}`,
        },
      });
      this.nav_history = response.data.dataNav.dataNav;
      this.loadingTime = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.switching {
  background: #f3f4f6;
}
.switching-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

hr {
  margin: 0 !important;
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #111827;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
  }
}

.minimum-subscription {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #1f2937;
    text-align: right;
  }
}

.subscription-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: right;
    color: #38a169;
  }
}

.redeem-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: right;
    color: #38a169;
  }
}

.switching-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: right;
    color: #38a169;
  }
}

.release-date {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #1f2937;
    text-align: right;
  }
}

.min-buy {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #1f2937;
    text-align: right;
  }
}

.min-buy-next {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #1f2937;
    text-align: right;
  }
}

.min-sell {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #1f2937;
    text-align: right;
  }
}

.fee {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #1f2937;
    text-align: right;
  }
}

.fund-type {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #1f2937;
    text-align: right;
  }
}

.custodian-bank {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }
  .custodian-bank-name {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #1f2937;
    text-align: right;
  }
  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    color: #1f2937;
    text-decoration: none;
  }
}

.nab span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #e60012;
  background: #fcddd9;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.nab p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #e60012;
  background: #fcddd9;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.nab-value {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 11.4286px;

    color: #9ca3af;
    vertical-align: text-top;
  }
}

.aum span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #5c0dab;
  background: #ebd9fc;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.aum p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #5c0dab;
  background: #ebd9fc;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.participation-unit span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #29064c;
  background: #ffe299;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.participation-unit p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #29064c;
  background: #ffe299;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.aum-value {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 11.4286px;

    color: #9ca3af;
    vertical-align: text-top;
  }
}

.participation-unit-value {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 11.4286px;

    color: #9ca3af;
  }
}

.document p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #111827;
}

.prospectus {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #111827;
  }
  a .value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    text-align: right;
    color: #6b7280;
    text-align: right;
    text-decoration: none;
  }
}

.fund-fact-sheet {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #111827;
  }
  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    text-align: right;
    color: #6b7280;
    text-align: right;
  }
  a {
    text-decoration: none;
  }
}

.price-terms p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #111827;
}

.transaction-time-before {
  .before {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
  .before-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.transaction-time-after {
  .after {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
  .after-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.nav-chart {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
    margin-left: 15px;
  }
  .nav-link {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    background: #f7f7f8;
    color: #4b5563;
    padding: 10px 40px 10px 40px;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .nav-link:hover {
    background: $secondaryColor;
    color: white;
  }
}

.aum-chart {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
    margin-left: 15px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
    margin-left: 15px;
  }
  .nav-link {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    background: #f7f7f8;
    color: #4b5563;
    padding: 10px 40px 10px 40px;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .nav-link:hover {
    background: $primaryColor;
    color: white;
  }
}

.risk-profile-modal {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #111827;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $secondaryColor;
}

.custom-checkbox:active {
  box-shadow: none !important;
}
.custom-checkbox:focus {
  box-shadow: none !important;
}

.disclaimer {
  .card {
    background: #ebf8ff;
    border: 1px solid #90cdf4;
    box-shadow: 0px 1px 2px rgba(107, 114, 128, 0.06),
      0px 1px 3px rgba(107, 114, 128, 0.1);
    border-radius: 6px;
  }
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #2c5282;
    margin: 0px;
  }
}

.risk-profile-modal {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #111827;
  }
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 11px !important;
  }
  .product-performance-row {
    margin-top: 30px;
  }
  .fund-performance {
    text-align: center;
  }
  .btn-tertiary {
    font-size: 14px !important;
  }
  .disclaimer {
    p {
      font-size: 14px;
    }
  }
  .disclaimer-text {
    font-size: 16px !important;
  }
  label {
    font-size: 14px !important;
  }

  .nab p {
    font-size: 11px;
  }

  .nab-value {
    p {
      font-size: 14px;
    }

    span {
      font-size: 11px;
    }
  }
  .aum p {
    font-size: 11px;
  }

  .participation-unit p {
    font-size: 11px;
  }
  .aum-value {
    p {
      font-size: 14px;
    }

    span {
      font-size: 11px;
    }
  }

  .participation-unit-value {
    p {
      font-size: 14px;
    }

    span {
      font-size: 11px;
    }
  }
}
</style>
